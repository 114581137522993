import { parameterize } from "@lib/utils"

export const transformButton = (input) => {
  return {
    ...input,
    theme: parameterize(input.buttonTheme)
  }
}

export const transformImage = (input) => {
  // TODO streamline src to reduce use of aliases
  return {
    ...input,
    ...input.image,
    src: input.src || input.url || input.image?.src || input.image?.url
  }
}

const transformed = (input) => {
  return {
    ...input,
    fontColor: parameterize(input.fontColor),
    backgroundColor: parameterize(input.backgroundColor)
  }
}

export default transformed
