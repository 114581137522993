export default {
  check: {
    listItems: ["List item 1", "List item 2", "List item 3", "List item 4"],
    bulletSvg: "check"
  },
  bullet: {
    listItems: ["List item 1", "List item 2", "List item 3", "List item 4"],
    bulletSvg: "bullet",
    color: "saturated-red"
  }
}
