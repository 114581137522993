import { parameterize } from "@lib/utils"

const transformed = (input) => {
  return {
    ...input,
    bulletSvg: parameterize(input.bulletSvg),
    color: parameterize(input.color)
  }
}

export default transformed
