export default {
  props: {
    videoUrl: "06G0lxaSjgk"
  },
  withCaption: {
    caption: "Adding captions can help to clarify callouts and assist with flow of communication"
  },
  loopingAndAutoplay: {
    videoFile: {
      url: "https://videos.ctfassets.net/5vqt5w81x2im/33Sw87Rm2rac9xrkyDRod/a874dec4891a562f4c2770ef314bf515/CDP_Page_Hero__1_.mp4"
    },
    loopAndAutoplay: true
  },
  thumbnail: {
    thumbnail: {
      width: 2160,
      height: 1400,
      alt: "Thumbnail for a video",
      src: "https://images.ctfassets.net/5vqt5w81x2im/5m68L13ATkIMxAJoYP0YOK/225e61b49b6f59a6e9f0330022e30184/Webinar_CDPAtoZ_Part1_Hero.jpg"
    }
  }
}
