import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { ReactSVG } from "react-svg"

// ---------------------------------------------------------

import styles from "./styles.module.scss"

// ---------------------------------------------------------

const BulletList = ({ listItems, bulletSvg, color }) => {
  const svgClasses = classNames(styles.svg_wrapper, {
    [styles[`svg_${color}`]]: color
  })

  return (
    <ul className={styles.bullet_list} data-testid="bullet-list">
      {listItems.map((item, i) => (
        <li key={i}>
          <span className={svgClasses}>
            <ReactSVG data-testid="bullet-list-svg" src={`/icons/${bulletSvg}.svg`} />
          </span>
          <p>{item}</p>
        </li>
      ))}
    </ul>
  )
}

BulletList.propTypes = {
  /**
   * Specifies the list Items that will be mapped
   */
  listItems: PropTypes.array,
  /**
   * Specifies the SVG to use as the bullets for the list
   */
  bulletSvg: PropTypes.oneOf(["bullet", "check"]),
  /**
   * Specifies the color of the SVG from a predefined palette.
   */
  color: PropTypes.oneOf([
    "lagoon",
    "limoncello",
    "off-black",
    "prime-green",
    "prime-red",
    "saturated-green",
    "saturated-red"
  ])
}

BulletList.defaultProps = {
  listItems: [],
  color: "off-black",
  bulletSvg: "check"
}

export default BulletList
