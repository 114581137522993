import PropTypes from "prop-types"
import classNames from "classnames"

// ---------------------------------------------------------
import { container, embedded, pingpong } from "./styles.module.scss"
// ---------------------------------------------------------

const Podcast = ({ url, theme }) => {
  const episodeId = url
    .replace("spotify.com/episode", "spotify.com/embed/episode")
    .match(/.+?(?=\?si)/)

  return (
    <div
      className={classNames(container, {
        [embedded]: theme == "embedded",
        [pingpong]: theme == "pingpong"
      })}
    >
      <iframe
        style={{ borderRadius: 12 }}
        src={episodeId}
        width="100%"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        title="podcast"
      ></iframe>
    </div>
  )
}

Podcast.propTypes = {
  /**
   * Spotify link - get it from "Share > Copy Episode Link"
   */
  url: PropTypes.string,

  /**
   * How the player gets styled
   */
  theme: PropTypes.oneOf(["pingpong", "embedded"])
}

export default Podcast
