/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import PropTypes from "prop-types"

const GoogleMap = (props) => {
  let { address, key } = props

  const src = `https://www.google.com/maps/embed/v1/place?key=${key}&q=${address}`

  return (
    <div>
      <iframe
        referrerPolicy="no-referrer-when-downgrade"
        style={{ width: "100%", height: "400px" }}
        src={src}
        allowFullScreen
      ></iframe>
    </div>
  )
}

GoogleMap.propTypes = {
  key: PropTypes.string,
  address: PropTypes.string
}

GoogleMap.defaultProps = {
  key: process.env.NEXT_PUBLIC_EMBED_MAP_KEY
}

export default GoogleMap
